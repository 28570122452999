<template>
    <div v-if="bottomBar" class="mob-top-bar">
      <router-link to="/pikles" class="home-logo-mob">
        <v-img
          alt="Pikle Logo"
          :src="require('../../assets/logo-full.svg')"
          max-width="76"
        ></v-img>
      </router-link>
      <UserMenu absolute />
      <AppBottomBar :navList="nav" />
    </div>
    <!-- <v-toolbar v-else light dense :elevation="1" :absolute="absolute"> -->
    <div v-else>
      <v-progress-linear v-if="navigatingToDecision" height="2" style="z-index: 200;" indeterminate></v-progress-linear>
      <v-app-bar app color="white" :elevation="1">
        <v-container :fluid="navigatingToDecision" class="pa-0 fill-height">
          <router-link to="/pikles">
            <v-img
              alt="Pikle Logo"
              :src="require('../../assets/logo-full.svg')"
              max-width="76"
            ></v-img>
          </router-link>
          <div v-if="currentTaxon" class="taxon-title">{{currentTaxon.name}}</div>
          <v-spacer></v-spacer>
          <v-toolbar-items v-if="showNav" class="mr-6">
          <v-btn
            class="app-bar-button"
            v-for="item in nav"
            :key="item.icon"
            :to="item.requiresRegion ? regionalPath(item.path) : item.path"
            active-class="active-section"
            text
          >
            <v-icon small class="mr-1">{{ item.icon }}</v-icon> {{ $t(`${item.i18n}`) }}
          </v-btn>
          </v-toolbar-items>
          <v-toolbar-items>
            <v-list-item class="px-0">
              <UserMenu separateRegionMenu />
            </v-list-item>
          </v-toolbar-items>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>

import UserMenu from './UserMenu'
import AppBottomBar from './AppBottomBar'

import taxonomyAPI from '../../api/taxonomy'

import { mapState } from 'vuex'

export default {
  name: 'AppToolbar',
  props: {
    showNav: {
      type: Boolean,
      default: true
    },
    absolute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // nav: [
      //   {
      //     icon: 'mdi-magnify',
      //     i18n: 'navigation.app.discover',
      //     path: '/discover',
      //     requiresRegion: true
      //   },
      //   {
      //     icon: 'mdi-star-outline',
      //     i18n: 'navigation.app.decisions',
      //     path: '/pikles'
      //   }
      // ],
      currentTaxon: null
    }
  },
  computed: {
    ...mapState({
      navigatingToDecision: state => state.pikleBoard.navigating,
      currentCountry: state => state.user.currentCountry,
      currentTaxonRegion: state => state.user.currentTaxonRegion
    }),
    bottomBar () {
      let res = true
      switch (this.$vuetify.breakpoint.name) {
        case 'md': res = false; break;
        case 'lg': res = false; break;
        case 'xl': res = false; break;
      }
      return res
    },
    nav () {
      const res = [{
          icon: 'mdi-star-outline',
          i18n: 'navigation.app.decisions',
          path: '/pikles'
        }]

      if (this.currentCountry === 'NZ') {
        res.unshift({
          icon: 'mdi-magnify',
          i18n: 'navigation.app.discover',
          path: '/discover',
          requiresRegion: true
        })
      }
      return res
    }
  },
  components: {
    UserMenu,
    AppBottomBar
  },
  watch: {
    $route () {
      this.currentTaxon = null
    },
    // Whenver the decision id changes this will run and load appropriate data
    currentTaxonRegion: async function (newVal) {
      if (newVal) {
        this.getCurrentTaxon()
      }
    },
    prefsLoaded: function (newVal) {
      if (newVal && !this.inited) {
        this.initUserData()
      }
    }
  },
  async mounted() {
    this.getCurrentTaxon()
  },
  methods: {
    regionalPath: function (basePath) {
      if (this.currentCountry) {
        return `/${this.currentCountry.toLowerCase()}${basePath}`
      } else {
        return basePath
      }
    },
    getCurrentTaxon: async function () {
      const currentTaxonParam = this.$route.params.taxonId
      if (currentTaxonParam) {
        const categoryResponse = await taxonomyAPI.getCategoryData(currentTaxonParam, this.currentTaxonRegion)
        if (categoryResponse && categoryResponse.status === 'success' && categoryResponse.category) {
          this.currentTaxon = categoryResponse.category
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.mob-top-bar {
  position: fixed;
  top: 0;
  width: 100%;
  height: 45px;
  background-color: #FFF;
  z-index: 200;
  border-bottom: 1px solid #CCC;

  .home-logo-mob {
    position: absolute;
    top: 0px;
    left: 16px;
  }
}

.app-bar-button {
  text-transform: capitalize;
  margin-top: 0px;
  padding: 30px 20px !important;
  color: #222;
  font-weight: 400;
  font-size: 0.8rem;

  &.active-section {
    color: #000;
    &:before {
      background-color: #FFF;
      border-bottom: 1px solid #d900ff;
      opacity: 1 !important;
    }

    .v-icon {
      color: #d900ff;
    }
  }
}

.taxon-title {
  font-size: 1.8rem;
  margin-top: 5px;
  margin-left: 20px;
  color: #5b4c4c;
}
</style>
