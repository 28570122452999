import { onSnapshot } from "firebase/firestore";

import Constants from '../../constants.js'
import decisionsAPI from '../../api/decisions'
import decisionAPI from '../../api/decision'
import optionAPI from '../../api/option'
import Vue from 'vue'

import * as types from '../types/pikleBoard'
import * as commonTypes from '../types/common'
import * as decisionsTypes from '../types/decisions'
// import * as userTypes from '../types/user'

export const getDefaultState = () => ({
  decision: null,
  expansionPanelState: null,
  newCriteriaDialogState: false,
  viewingAsPublished: false,
  autoCheckOptionValues: false,
  allResearchDocs: [],
  allOptions: [],
  newlyAddedRecommendations: [],
  members: [], // This is the list of up to date members data loaded by a snapshot subscriber
  fetchingStatus: false,
  fetchingOptionsStatus: false,
  decisionUnsubscribeCallback: null,
  optionsUnSubscribeCallback: null,
  researchDocsUnSubscribeCallback: null,
  membersUnSubscribeCallback: null,
  localSortingMethods: [
    {id: 'createdAt', label: 'Date Created', description: 'Order by date created'},
    {id: 'name', label: 'Name', description: 'Order by option name'},
    {id: 'criteria', label: 'Feature', description: 'Order by selected feature'}
  ],
  serverSideSortingMethods: [
    {id: 'createdAt', label: 'Date Created', description: 'Order by date created'},
    {id: 'scrapeState', label: 'Approval Status', description: 'Order by approval state'}
  ],
  recommendationSortingMethods: [
    {id: 'createdAt', label: 'Date Created', description: 'Order by date created'},
    {id: 'contentState.checkedAt', label: 'Time Checked', description: 'Order by time content was last checked'},
    {id: 'approvalCount', label: 'Approval Count', description: 'Order by approval count'}
  ],
  filterOnNewValues: false,
  filterOnUnApprovedOptions: null,
  filterOnRequiresReview: null,
  filterOnMissingSummary: null,
  pageSize: 10, // Cannot exceed 10 because of firestore query limitations see https://firebase.google.com/docs/firestore/query-data/queries#in_not-in_and_array-contains-any
  pageIndex: 0,
  recommendationsDecisionId: null,
  navigating: false,
  showSubstitutes: false,
  selectedOption: null,
  fullScreenOptionOpen: false,
  showFeaturePluginDialog: false,
  activeFeaturePlugin: null,
  showAllCriteria: false,
  showAllComments: false,
  minVisibleCriteria: 3,
  featureDescriptions: {},
  featureNotes: {}
})

// initial state
const state = getDefaultState()

// getters
const getters = {
  decisionId: state => {
    return state.decision ? state.decision.id : null
  },
  decisionName: state => {
    return state.decision ? state.decision.name : null
  },
  sortingMethods: state => {
    let res = state.localSortingMethods
    if (state.decision && state.decision.appKey) {
      res = state.serverSideSortingMethods
    }
    else if (state.decision && state.decision.isSuggestionDoc) {
      res = state.recommendationSortingMethods
    }
    return res
    // return state.decision && state.decision.appKey ? state.serverSideSortingMethods : state.localSortingMethods
  },
  decisionOwner: state => {
    return state.decision ? state.decision.owner : null
  },
  decisionAppKey: state => {
    return state.decision ? state.decision.appKey : null
  },
  isSuggestionDoc: state => {
    return state.decision ? state.decision.isSuggestionDoc : false
  },
  taxonRegion: state => {
    return state.decision ? state.decision.taxonRegion : 'australasia'
  },
  scraperRegion: state => {
    return state.decision ? state.decision.scraperRegion : null
  },
  decisionOptionsCount: state => {
    return state.decision ? state.decision.optionsCount : 0
  },
  currentTaxon: state => {
    return state.decision && state.decision.category ? state.decision.category.id : null
  },
  chosenOptionId: state => {
    return state.decision && state.decision.chosenOption && typeof(state.decision.chosenOption) !== 'undefined' ? state.decision.chosenOption :  null;
  },
  chosenOption: state => {
    let res = null
    if (state.decision && state.decision.chosenOption && typeof(state.decision.chosenOption) !== 'undefined') {
      res = state.allOptions.find(option => option.id === state.decision.chosenOption)
    }
    return res
  },
  currentList: state => {
    return state.decision ? state.decision.currentList : null;
  },
  sortAscending: state => {
    return state.decision ? state.decision.sortAscending : false;
  },
  sortMethod: state => {
    return state.decision ? state.decision.sortMethod : null;
  },
  sortCriteria: state => {
    return state.decision ? state.decision.sortCriteria : null;
  },
  researchDocs: state => {
    return state.allResearchDocs
  },
  sortedResearchDocs: state => {
    return state.allResearchDocs
  },
  decisionCriteria: state => {
    let res = {}
    if (state.decision) {
      res = state.decision.criteria || {}
    }
    return res
  },
  decisionMembers: state => {
    // Returns the members map for the decision. Just contains roles and id not user data. See state.members for users data.
    return state.decision && state.decision.members ? state.decision.members : {};
  },
  decisionCriteriaRanges: state => {
    let res = {}
    if (state.decision) {
      res = state.decision.criteriaValueRanges || {}
    }
    return res
  },
  criteriaList: state => {
    let res = []
    if (state.decision && state.decision.criteria) {
      res = Object.keys(state.decision.criteria).map( (key) => {
          const criteriaItem = { ...state.decision.criteria[key] }
          criteriaItem.id = key
          const valueRanges = state.decision && state.decision.criteriaValueRanges || {}
          if (valueRanges[criteriaItem.id]) {
            criteriaItem.range = valueRanges[criteriaItem.id]
          }
          return criteriaItem
      })
    }
    res.sort((a, b) => a.order - b.order)
    return res
  },
  totalOptionsCount: state => {
    return state.allOptions.filter(next => !next.substituteFor).length
  },
  researchDocsCount: state => {
    return state.allResearchDocs.length
  },
  shortListCount: state => {
    return state.allOptions.filter(option => option.shortListed).length
  },
  optionsForCurrentList: state => {
    let res = []
    if (state.decision) {
      if (state.decision.appKey) {
        res = state.allOptions
      }
      else if (state.decision.isSuggestionDoc) {
        res = state.showSubstitutes ? state.allOptions : state.allOptions.filter(option => !option.substituteFor)
      }
      // else if (state.viewingAsPublished) {
      //   res = state.allOptions.filter(option => !option.substituteFor)
      // }
      else {
        // For normal modes show the list filtered of substitutes or by shortlisted items based on the decision settings
        res = state.decision.currentList === 1 ? state.allOptions.filter(option => !option.substituteFor) : state.allOptions.filter(option => option.shortListed)
      }
    }
    return res
  },
  shortListOptions: state => {
    return state.allOptions.filter(option => option.shortListed)
  }
}

// actions
const actions = {
  [commonTypes.ACTION_RESET_STATE]: ({ commit }) => {
    // First unsubscribe from any data listeners
    commit(types.MUTATION_SET_DECISION_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_SET_OPTIONS_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_SET_RESEARCH_DOCS_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_SET_MEMBERS_UNSUBSCRIBE_CALLBACK, null)
    // Then reset the whole state
    commit(commonTypes.MUTATION_RESET_STATE)
  },
  [types.ACTION_LOAD_DECISION]: async ({ state, commit, dispatch }, payload) => {
    // Unload any existing decision data. AKA reset
    // console.log(`Loading decision ${JSON.stringify(payload)}`)
    dispatch(commonTypes.ACTION_RESET_STATE)
    commit(types.MUTATION_FETCHING_STATUS, true)
    commit(types.MUTATION_SET_AUTO_CHECK_OPTION_VALUES, payload.autoCheckOptionValues)
    commit(types.MUTATION_SET_IS_NAVIGATING, false)

    let decisionObj = null
    if (payload.decisionId) {
      decisionObj = await decisionsAPI.getDecisionData(payload.decisionId)
    } else if (payload.appId && payload.taxonId) {
      decisionObj = await decisionsAPI.getAppDecisionData(payload.appId, payload.taxonId)
    }
    else if (payload.taxonId && payload.taxonRegion) {
      const scraperRegion = payload.scraperRegion || null
      // const recommendationDecisionId = await decisionAPI.getRecommendationsDocId(payload.taxonId, payload.taxonRegion, scraperRegion)
      decisionObj = await decisionAPI.getRecommendationsDoc(payload.taxonId, payload.taxonRegion, scraperRegion)
    }

    // decisionsAPI.getDecisionData(decisionId).then(decisionObj => {
      if (decisionObj) {
        // const decisionId = decisionObj.id
        commit(types.MUTATION_SET_DECISION, decisionObj)
        const decisionRef = decisionAPI.decisionRef(decisionObj.id)
        const decisionUnsubscribe = onSnapshot(decisionRef, (snapshot) => {
          if (snapshot && snapshot.data() && state.decision) {
            const modifiedData = snapshot.data()
            if (modifiedData.shortListSummary && !state.decision.shortListSummary) {
              commit(types.MUTATION_SHORTLIST_SUMMARY, modifiedData.shortListSummary)
            } else if (!modifiedData.shortListSummary && state.decision.shortListSummary) {
              commit(types.MUTATION_SHORTLIST_SUMMARY, null)
            }

            if (modifiedData.commentsCount !== state.decision.commentsCount) {
              commit(types.MUTATION_COMMENTS_COUNT, modifiedData.commentsCount)
            }
          }
        })

        commit(types.MUTATION_SET_DECISION_UNSUBSCRIBE_CALLBACK, decisionUnsubscribe)
        
        // commit(types.MUTATION_FETCHING_STATUS, false)
        // Set the current list
        if (typeof(decisionObj.currentList) !== 'undefined') {
          commit(types.MUTATION_SET_CURRENT_LIST, decisionObj.currentList)
        } else {
          commit(types.MUTATION_SET_CURRENT_LIST, 1)
        }

        const optionsFilter = payload.optionsFilter || null
        // console.log(`optionsFilter = ${JSON.stringify(optionsFilter)}`)
        if (payload.filterApprovedRecommendations) {
          commit(types.MUTATION_SET_FILTER_ON_UNAPPROVED_OPTIONS, false)
        }
        dispatch(types.ACTION_LOAD_DECISION_OPTIONS, {optionsFilter: optionsFilter, useExistingTotal: true})

        if (decisionObj.criteriaDescriptions) {
          const featureDescriptionsStateMap = {}
          Object.keys(decisionObj.criteriaDescriptions).forEach((nextKey) => {
            featureDescriptionsStateMap[nextKey] = {description: decisionObj.criteriaDescriptions[nextKey], visible: false}
          })

          commit(types.MUTATION_SET_FEATURE_DESCRIPTIONS, featureDescriptionsStateMap)
        }

        // Load feature notes if this is an app based decision
        if (payload.appId && payload.taxonId) {
          const appFeatureNotesMap = await decisionAPI.getAppFeatureNotes(payload.appId, payload.taxonId)
          commit(types.MUTATION_SET_FEATURE_NOTES, appFeatureNotesMap)
        }
      }

      commit(types.MUTATION_FETCHING_STATUS, false)
      return true
    // })
  },
  [types.ACTION_LOAD_DECISION_MEMBERS]: async ({ state, commit }, payload) => {
    commit(types.MUTATION_SET_MEMBERS_UNSUBSCRIBE_CALLBACK, null)
    commit(types.MUTATION_CLEAR_MEMBERS, null)
    // if (!state.membersUnSubscribeCallback) {
    const unsubscribe = onSnapshot(decisionAPI.decisionMembersRef(state.decision.id, [payload.ownerId, ...payload.memberIds]), (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const userData = {id: change.doc.id, ... change.doc.data()}
          if (userData.id === payload.ownerId) {
            userData.isOwner = true
          }
          commit(types.MUTATION_ADD_MEMBER, userData)
        }
      })
    })

    commit(types.MUTATION_SET_MEMBERS_UNSUBSCRIBE_CALLBACK, unsubscribe)
    // }
  },
  [types.ACTION_LOAD_DECISION_OPTIONS]: async ({ state, commit, dispatch }, payload) => {
    // App based decisions can contain thousands of options and do server side pagination to build the data listener
    // let optionURLs = payload && payload.urls ? payload.urls : null;
    let optionIDs = payload && payload.optionsFilter ? payload.optionsFilter : null;
    let paginatedList = false
    let queryEmpty = false
    if (state.decision && (state.decision.appKey || state.decision.isSuggestionDoc )) {
      paginatedList = true
      commit(types.MUTATION_SET_OPTIONS_UNSUBSCRIBE_CALLBACK, null) // Clear any existing data subscription
      commit(types.MUTATION_FETCHING_OPTIONS_STATUS, true)
      const sortingMethod = state.decision.sortMethod || 'createdAt'
      let totalOptionCount = optionIDs && optionIDs.length > 0 ? optionIDs.length : 0;
      if (!optionIDs) {
        // console.log(`Getting paginated response using sort method ${sortingMethod}`)
        const useExistingTotal = payload && (payload.useExistingTotal === true || payload.useExistingTotal === false) ? payload.useExistingTotal : false
        // todo get ID of last option in the list of options if pageIndex > 1 and pass that
        let lastOptionInList = null
        if (state.pageIndex > 0 && state.allOptions) {
          lastOptionInList = state.allOptions[state.allOptions.length-1]
        }
        const paginatedOptionsResponse = await decisionAPI.getPaginatedOptions(state.decision.id, state.pageSize, lastOptionInList, sortingMethod, !state.decision.sortAscending, state.filterOnNewValues, state.filterOnUnApprovedOptions, state.filterOnRequiresReview, state.filterOnMissingSummary, !useExistingTotal)
        if (paginatedOptionsResponse.options) {
          optionIDs = paginatedOptionsResponse.options.map(next => next.id)
        }
        
        if (useExistingTotal) {
          totalOptionCount = state.decision.optionsCount
        } else {
          totalOptionCount = paginatedOptionsResponse.totalOptionsCount
        }

        if (totalOptionCount === 0) {
          queryEmpty = true
        }
      }
      // Set the new total options which can be greater than the allOptions due to pagination
      commit(types.MUTATION_SET_DECISION_OPTION_COUNT, totalOptionCount)
      commit(types.MUTATION_FETCHING_OPTIONS_STATUS, false)
      // Clear the options list so it can be built by the snapshot listener
      commit(types.MUTATION_OPTIONS, [])
    }
    if (state.decision && !state.optionsUnSubscribeCallback && !queryEmpty) {
      const optionsRef = paginatedList && optionIDs && optionIDs.length > 0 ? decisionAPI.decisionOptionsRef(state.decision.id, optionIDs) : decisionAPI.allDecisionOptionsRef(state.decision.id)
      const unsubscribe = onSnapshot(optionsRef, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          // console.log(`options data subscription picked up a change of type ${change.type}`)
          let foundOption = null;
          if (state.allOptions) {
            foundOption = state.allOptions.find(next => next.id === change.doc.id)
          }
          if (change.type === "added") {
            
            if (!foundOption) {
              const optionData = {id: change.doc.id, ...change.doc.data()}
              commit(types.MUTATION_ADD_OPTION, optionData)

              if (optionData.substituteFor) {
                dispatch(types.ACTION_LOAD_OPTION_STORES, optionData.substituteFor)
              }
              // For normal user decisions the allOptions list is the same as the total options in the DB.
              // For app based decisions we get the list of IDs from the server and the total is not synced here
              if (!paginatedList) {
                commit(types.MUTATION_SET_DECISION_OPTION_COUNT, state.allOptions.length)
              }
            }
          }
          if (change.type === "modified") {
            // commit(types.MUTATION_UPDATE_DECISION, {id: change.doc.id, updates: change.doc.data()})
            // let foundOption = null;
            // if (state.allOptions) {
            //   foundOption = state.allOptions.find(next => next.id === change.doc.id)
            // }
            if (foundOption) {
              const modifiedData = change.doc.data()

              // console.log(`HANDLE MODIFIED OPTION`)
              // console.log(JSON.stringify(modifiedData))

              // console.log(`FOUND OPTION`)
              // console.log(JSON.stringify(foundOption))

              // Handle changes in pros and cons counts
              if (foundOption.prosCount !== modifiedData.prosCount) {
                commit(types.MUTATION_OPTION_PROS_COUNT, { optionId: change.doc.id, count: modifiedData.prosCount })
              }
              if (foundOption.consCount !== modifiedData.consCount) {
                commit(types.MUTATION_OPTION_CONS_COUNT, { optionId: change.doc.id, count: modifiedData.consCount })
              }
              // Keep stores up to date
              if (foundOption.substituteFor !== modifiedData.substituteFor) {
                // If substituteFor changes, we can update the parent(s) to reload their stores
                const oldValue = foundOption.substituteFor
                // Update the local value
                commit(types.MUTATION_OPTION_SUBSTITUTE_FOR, { optionId: change.doc.id, substituteFor: modifiedData.substituteFor })

                // Reload stores for new sub
                if (modifiedData.substituteFor) {
                  dispatch(types.ACTION_LOAD_OPTION_STORES, modifiedData.substituteFor)
                }
                // Reload stores for old sub
                if (oldValue) {
                  dispatch(types.ACTION_LOAD_OPTION_STORES, oldValue)
                }
              }
              // Dont think that is needed.
              // else {
              //   dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.id)
              // }

              // Keep broken state up to date
              if (foundOption.contentState && modifiedData.contentState && foundOption.contentState.broken !== modifiedData.contentState.broken) {
                commit(types.MUTATION_UPDATE_OPTION_DATA, {optionId: foundOption.id, optionData: {contentState: modifiedData.contentState}})
              }

              // console.log(`Option state ${foundOption.scrapeState} modified to ${modifiedData.scrapeState}`)
              // Compate scrape state of local option with the stored document to determine if we have a new scrape result
              if (
                (foundOption.scrapeState === Constants.SCRAPE_STATES.unscraped || foundOption.scrapeState === Constants.SCRAPE_STATES.scraping) &&
                modifiedData.scrapeState === Constants.SCRAPE_STATES.scraped
              ) {
                // The scrape state changed to scraped from unscraped or scraping.
                // So handle the latest scrape result
                // console.log(`Option was scraped`)
                let scrapeResult = modifiedData.scrapeResult
                const newImageOptions = scrapeResult && scrapeResult.imageOptions ? scrapeResult.imageOptions : [{url: modifiedData.imgSrc, ranking: 1, position: 1}];

                if (!scrapeResult) {
                  scrapeResult = {
                    url: modifiedData.url,
                    title: modifiedData.name,
                    sha256: modifiedData.sha256,
                    imageOptions: newImageOptions //[{url: modifiedData.imgSrc, ranking: 1, position: 1}]
                  }
                }

                commit(types.MUTATION_OPTION_SCRAPE_DATA, {
                  id: foundOption.id,
                  name: modifiedData.name,
                  imgSrc: modifiedData.imgSrc,
                  sha256: modifiedData.sha256,
                  scrapeResult: scrapeResult,
                  criteriaValues: modifiedData.criteriaValues,
                  criteriaValueOptions: modifiedData.criteriaValueOptions,
                  scrapeState: modifiedData.scrapeState,
                  contentPushed: modifiedData.contentPushed
                })

                commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
              }
              else if (modifiedData.rescrapeResult && modifiedData.rescrapeResult.newValues && modifiedData.scrapeState === Constants.SCRAPE_STATES.approved) {
                // console.log(`Was validating now finished`)
                // Scrape state changed from validating to approved.
                const checkedValuesScrapeResult = modifiedData.rescrapeResult
                // const newSha256 = checkedValuesScrapeResult.sha256
                const newValues = checkedValuesScrapeResult.newValues || null
                let foundValueDifferences = false
                // Check if the newValues structure has any updated values
                if (newValues && !(Object.keys(newValues).length === 0 && newValues.constructor === Object)) {
                  foundValueDifferences = true
                }

                // If we have new values then set the values and scrape state from the stored doc so the UI can suggest them
                if (foundValueDifferences) {
                  // console.log(`Found value differences`)
                  // commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
                  commit(types.MUTATION_OPTION_RE_SCRAPE_RESULT, {
                    id: foundOption.id,
                    rescrapeResult: checkedValuesScrapeResult
                  })
                }
                // else if (newSha256 && foundOption.sha256 !== newSha256) {
                //   // If there are no new values, just sync the sha256 hash
                //   // console.log(`No new values`)
                //   commit(types.MUTATION_OPTION_HASH, {id: foundOption.id, sha256: newSha256})
                //   commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
                // }

                commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
                commit(types.MUTATION_UPDATE_CONTENT_STATE, {id: foundOption.id, contentState: modifiedData.contentState})
                commit(types.MUTATION_OPTION_HASH, {id: foundOption.id, sha256: modifiedData.sha256})

                // If this option has substituteFor, then we need to reload the stores to keep them up to date in the UI.
                if (foundOption.substituteFor) {
                  // console.log(`Modified option is a sub, so re loading stores for the sub`)
                  dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.substituteFor)
                }
                // If this option has substituteFor, then we need to reload the stores to keep them up to date in the UI.
                else if (foundOption.stores && foundOption.stores.length > 1) {
                  // console.log(`Modified option has stores, so reloading stores (which can include the option itself)`)
                  dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.id)
                }
              }
              else if (foundOption.rescrapeResult && foundOption.rescrapeResult.newValues && !modifiedData.rescrapeResult) {
                // Reload option stores to reflect any changes after approving rescrape results
                dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.id)
              }
              else if (foundOption.scrapeState === Constants.SCRAPE_STATES.unscraped && modifiedData.scrapeState === Constants.SCRAPE_STATES.failed) {
                // Handle change to a failed scrape state
                // console.log(`Option in failed state`)
                commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
              }

              else if (foundOption.contentState && modifiedData.contentState && modifiedData.contentState.checkedAt > foundOption.contentState.checkedAt) {
                // if (modifiedData.contentState && modifiedData.contentState.sha256 !== modifiedData.sha256) {
                //   // console.log(`Content state Sha256 is different to option sha256`)
                //   // This forces us to pick up rescrape result when the option is next updated updated
                //   commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: Constants.SCRAPE_STATES.validating})
                // }

                // In the default case (given we have a content state), just synch the local state with the store docs state
                commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
                commit(types.MUTATION_UPDATE_CONTENT_STATE, {id: foundOption.id, contentState: modifiedData.contentState})
                commit(types.MUTATION_OPTION_HASH, {id: foundOption.id, sha256: modifiedData.sha256})
                // commit(types.MUTATION_UPDATE_OPTION_CHECKED_TIME, {id: foundOption.id, lastCheckTime: modifiedData.contentState.checkedAt})

                // If this option has substituteFor, then we need to reload the stores to keep them up to date in the UI.
                if (foundOption.substituteFor) {
                  // console.log(`Modified no changes option is a sub, so re loading stores for the sub`)
                  dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.substituteFor)
                }
                // If this option has substituteFor, then we need to reload the stores to keep them up to date in the UI.
                else if (foundOption.stores && foundOption.stores.length > 1) {
                  // console.log(`Modified no changes option has stores, so reloading stores (which can include the option itself)`)
                  dispatch(types.ACTION_LOAD_OPTION_STORES, foundOption.id)
                }
              }
              else if (foundOption.scrapeState === Constants.SCRAPE_STATES.unscraped && modifiedData.scrapeState === Constants.SCRAPE_STATES.approved && (!modifiedData.criteriaValues || Object.keys(modifiedData.criteriaValues).length === 0) /** No criteria */) {
                // Handle auto approved options. These go from unscrapped directly to approved in the case when there are no criteria to scrape.
                commit(types.MUTATION_OPTION_SCRAPE_DATA, {
                  id: foundOption.id,
                  name: modifiedData.name,
                  imgSrc: modifiedData.imgSrc,
                  sha256: modifiedData.sha256,
                  criteriaValues: modifiedData.criteriaValues || {},
                  contentPushed: modifiedData.contentPushed
                })

                commit(types.MUTATION_OPTION_SCRAPE_STATE, {id: foundOption.id, scrapeState: modifiedData.scrapeState})
                commit(types.MUTATION_UPDATE_CONTENT_STATE, {id: foundOption.id, contentState: modifiedData.contentState})
              }
              else if (modifiedData.imgSrc && (!foundOption.imgSrc || foundOption.imgSrc !== modifiedData.imgSrc)) {
                // Handle update to imgSrc
                commit(types.MUTATION_OPTION_IMAGE, {id: foundOption.id, imgSrc: modifiedData.imgSrc})
              }
            }
          }
          if (change.type === "removed") {
            // Handle removed document
            // commit(types.MUTATION_REMOVE_OPTION, change.doc.id)
            const changeDocData = change.doc.data()
            dispatch(types.ACTION_DELETE_OPTION, {id: change.doc.id, ...changeDocData}).then (() => {
              
              // For normal user decisions the allOptions list is the same as the total options in the DB.
              // For app based decisions we get the list of IDs from the server and the total is not synced here
              if (!paginatedList) {
                commit(types.MUTATION_SET_DECISION_OPTION_COUNT, state.allOptions.length)
              }

              if (changeDocData.substituteFor) {
                dispatch(types.ACTION_LOAD_OPTION_STORES, changeDocData.substituteFor)
              }

          })
          }
        });
      });

      commit(types.MUTATION_SET_OPTIONS_UNSUBSCRIBE_CALLBACK, unsubscribe)
    }
  },
  [types.ACTION_DELETE_OPTION]: async ({ state, commit, dispatch }, option) => {
    commit(types.MUTATION_REMOVE_OPTION, option.id)
    if (option.substituteFor) {
      await dispatch(types.ACTION_LOAD_OPTION_STORES, option.substituteFor)
    }
    else if (option.dataRef) {
      // This option might have local imported substitutes, so remove any of those from the local list.
      const localSubs = state.allOptions.filter(otherOption => otherOption.imported && otherOption.substituteFor === option.id)
      localSubs.forEach(nextLocalSub => {
        commit(types.MUTATION_REMOVE_OPTION, nextLocalSub.id)
      })
    }
  },
  [types.ACTION_SAVE_CURRENT_LIST]: ({ state, commit }, listId) => {
    if (state.decision) {
      decisionAPI.saveCurrentList(state.decision.id, listId)
      commit(types.MUTATION_SET_CURRENT_LIST, listId)
    }
  },
  [types.ACTION_SAVE_OPTION_CRITERIA]: async ({ state, commit }, payload) => {
    let success = false
    if (state.decision) {
      const removeValueOptions = payload ? payload.cleanup === true : false; // Deletes the optionValues and rescrapeResults fields that we used to build the values from
      const recordLatestPrice = state.decision.isSuggestionDoc
      success = await optionAPI.saveOptionValues(state.decision.id, payload.optionId, payload.criteriaValues, removeValueOptions, recordLatestPrice)
      if (success) {
        commit(types.MUTATION_OPTION_CRITERIA_VALUES, {id: payload.optionId, criteriaValues: {...payload.criteriaValues}})
      }
    }
    return success
  },
  [types.ACTION_UPDATE_CRITERIA]: ({ state, commit }, payload) => {
    if (payload.criteria && (state.decision || payload.decisionId)) {
      const decisionId = payload.decisionId || state.decision.id

      decisionAPI.saveCriteria(decisionId, payload.criteria).then(updatedCriteriaObj => {
        if (updatedCriteriaObj) {
          commit(types.MUTATION_CRITERIA, updatedCriteriaObj)
          // Update each option to ensure that it has a criteria value object for any new criteria.
          const criteriaIds = Object.keys(updatedCriteriaObj)
          state.allOptions.forEach((opt) => {
            let foundNewCriteria = false
            criteriaIds.forEach((criteriaId) => {
              if (!opt.criteriaValues[criteriaId]) {
                foundNewCriteria = true
                opt.criteriaValues[criteriaId] = {userValue: null, sourceValue: null, newCriteria: true}
              }
            })

            if (foundNewCriteria) {
              commit(types.MUTATION_OPTION_CRITERIA_VALUES, {id: opt.id, criteriaValues: opt.criteriaValues})
            }
          })

        }
      })
    }
  },
  [types.ACTION_LOAD_RESEARCH_DOCS]: ({ state, commit }) => {
    if (state.decision) {
      // commit(types.MUTATION_FETCHING_STATUS, true)
      // commit(types.MUTATION_RESEARCH_DOCS, [])

      if (!state.researchDocsUnSubscribeCallback) {
        const unsubscribe = onSnapshot(decisionAPI.decisionResearchDocs(state.decision.id), (snapshot) => {
        // const unsubscribe = decisionAPI.decisionResearchDocs(state.decision.id).onSnapshot((snapshot) => {
          snapshot.docChanges().forEach((change) => {
            // console.log(`research docs subscription picked up a change of type ${change.type}`)
            if (change.type === "added") {
              let found = null;
              if (state.allResearchDocs) {
                found = state.allResearchDocs.find(next => next.id === change.doc.id)
              }
              if (!found) {
                commit(types.MUTATION_ADD_RESEARCH_DOC, {id: change.doc.id, ...change.doc.data()})
                commit(`decisions/${decisionsTypes.MUTATION_UPDATE_DECISION}`, {id: state.decision.id, updates: { researchDocsCount: state.allResearchDocs.length }}, { root: true })
              }
            }
            if (change.type === "removed") {
              // Handle removed document
              commit(types.MUTATION_REMOVE_RESEARCH_DOC, change.doc.id)
              commit(`decisions/${decisionsTypes.MUTATION_UPDATE_DECISION}`, {id: state.decision.id, updates: { researchDocsCount: state.allResearchDocs.length }}, { root: true })
            }
          });
        });

        commit(types.MUTATION_SET_RESEARCH_DOCS_UNSUBSCRIBE_CALLBACK, unsubscribe)
      }
      // decisionAPI.getResearchDocs(state.decision.id).then(researchDocs => {
      //   // const optionsMap = {}
      //   // Set the decision ID for each option and convert to a map for the Store
      //   researchDocs.forEach(doc => {
      //     doc.decisionId = state.decision.id
      //     // optionsMap[option.id] =  option
      //   })
      //
      //   // commit(types.MUTATION_FETCHING_STATUS, false)
      //   commit(types.MUTATION_RESEARCH_DOCS, researchDocs)
      // })
    }
  },
  // [types.ACTION_LOAD_OPTIONS]: ({ state, commit }) => {
  //   if (state.decisionId) {
  //     commit(types.MUTATION_OPTIONS, [])
  //     commit(types.MUTATION_FETCHING_STATUS, true)
  //     decisionAPI.getOptions(state.decisionId).then(options => {
  //       // const optionsMap = {}
  //       // Set the decision ID for each option and convert to a map for the Store
  //       options.forEach(option => {
  //         option.decisionId = state.decisionId
  //         // optionsMap[option.id] =  option
  //       })
  //
  //       commit(types.MUTATION_FETCHING_STATUS, false)
  //       commit(types.MUTATION_OPTIONS, options)
  //     })
  //   }
  // },
  [types.ACTION_TOGGLE_OPTION_SHORTLISTED]: ({ state, commit }, optionId) => {
    // console.log(`Toggling short listed`)
    const theOption = state.allOptions.find(option => option.id === optionId)
    if (state.decision && theOption) {
      // console.log(`Option ${optionId} current shortlisted state = ${theOption.shortListed}`)
      const toggledVal = !theOption.shortListed
      // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
      commit(types.MUTATION_OPTION_IS_SHORTLISTED, {id: optionId, isShortListed: toggledVal})
      optionAPI.setShortListed(state.decision.id, optionId, toggledVal).then(success => {
        if (!success) {
          commit(types.MUTATION_OPTION_IS_SHORTLISTED, {id: optionId, isShortListed: !toggledVal})
        } else {
          // Update the shortlisted count in the master decisions list
          const newShortListCount = state.allOptions.filter(option => option.shortListed).length
          commit(`decisions/${decisionsTypes.MUTATION_UPDATE_DECISION}`, {id: state.decision.id, updates: { shortListCount: newShortListCount }}, { root: true })
        }
      })
    }
  },
  [types.ACTION_CREATE_RESEARCH_DOC]: ({ state }, payload) => {
    if (payload.data && (state.decision || payload.decisionId)) {
      const decisionId = payload.decisionId || state.decision.id
      decisionAPI.createNewResearchDoc(decisionId, payload.data)
      // decisionAPI.createNewResearchDoc(state.decision.id, doc).then(res => {
      //   if (res && res.researchDocId) {
      //     const researchDoc = {id: res.researchDocId, ...doc}
      //     commit(types.MUTATION_ADD_RESEARCH_DOC, researchDoc)
      //     // Update the option count in the main decisions list
      //     commit(`decisions/${decisionsTypes.MUTATION_UPDATE_DECISION}`, {id: state.decision.id, updates: { researchDocsCount: state.allResearchDocs.length }}, { root: true })
      //   }
      // })
    }
  },
  [types.ACTION_REMOVE_RESEARCH_DOC]: ({ state, commit }, docId) => {
    if (state.decision) {
      commit(types.MUTATION_REMOVE_RESEARCH_DOC, docId)
      // Update the option count in the main decisions list
      commit(`decisions/${decisionsTypes.MUTATION_UPDATE_DECISION}`, {id: state.decision.id, updates: { researchDocsCount: state.allResearchDocs.length }}, { root: true })
    }
  },
  [types.ACTION_CREATE_NEW_OPTION]: async ({ state }, payload) => {
    // console.log(`Add option called`)
    let res = null
    if (payload.optionData && (state.decision || payload.decisionId)) {
      const decisionId = payload.decisionId || state.decision.id
      // No need to handle the response. The Options Data listener will add the new option to the store once it triggers
      res = await decisionAPI.createNewOption(decisionId, payload.optionData)
    }
    return res
  },
  [types.ACTION_TOGGLE_CHOSEN_OPTION]: ({ state, commit }, optionId) => {
    if (state.decision) {
      // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
      const oldVal = state.decision.chosenOption
      commit(types.MUTATION_CHOSEN_OPTION, optionId)
      decisionAPI.saveChosenOption(state.decision.id, optionId).then(res => {
        if (!res) {
          commit(types.MUTATION_CHOSEN_OPTION, oldVal)
        }
      })
    }
  },
  [types.ACTION_SET_SORT_METHOD]: ({ state, commit }, sortMethodId) => {
    // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
    if (state.decision) {
      const oldVal = state.decision.sortMethod
      commit(types.MUTATION_SORT_METHOD, sortMethodId)
      decisionAPI.saveUsersSortMethod(state.decision.id, sortMethodId, success => {
        if (!success) {
          // Saving faile so revert to old value
          commit(types.MUTATION_SORT_METHOD, oldVal)
        }
      })
    }
  },
  [types.ACTION_SET_SORT_CRITERIA]: ({ state, commit }, criteriaId) => {
    // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
    if (state.decision) {
      const oldVal = state.decision.sortCriteria
      commit(types.MUTATION_SORT_CRITERIA, criteriaId)
      decisionAPI.saveUsersSortCriteria(state.decision.id, criteriaId, success => {
        if (!success) {
          // Saving faile so revert to old value
          commit(types.MUTATION_SORT_CRITERIA, oldVal)
        }
      })
    }
  },
  [types.ACTION_TOGGLE_SORT_ASCENDING]: ({ state, commit }) => {
    // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
    if(state.decision) {
      const oldVal = state.decision.sortAscending || false;
      commit(types.MUTATION_SORT_ASCENDING, !oldVal)
      decisionAPI.saveUsersSortAscending(state.decision.id, state.decision.sortAscending, success => {
        if (!success) {
          // Saving faile so revert to old value
          commit(types.MUTATION_SORT_ASCENDING, oldVal)
        }
      })
    }
  },
  [types.ACTION_SET_FILTER_ONLY_CHANGES]: ({ /*state,*/ commit, dispatch }, newValue) => {
    // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
    // const oldValue = state.filterOnNewValues
    commit(types.MUTATION_SET_FILTER_ON_HAS_NEW_VALUES, newValue)
    // decisionAPI.saveUsersSortAscending(state.decision.id, state.decision.sortAscending, success => {
    //   if (!success) {
    //     // Saving faile so revert to old value
    //     commit(types.MUTATION_SET_FILTER_ON_HAS_NEW_VALUES, oldValue)
    //   }
    // })
    dispatch(types.ACTION_LOAD_DECISION_OPTIONS)
  },
  [types.ACTION_SET_FILTER_UNAPPROVED_OPTIONS]: ({ /*state,*/ commit, dispatch }, newValue) => {
    // Commit the mutation before updating the DB so we get immediate response in the UI, will revert if it fails
    // const oldValue = state.filterOnNewValues
    commit(types.MUTATION_SET_FILTER_ON_UNAPPROVED_OPTIONS, newValue)
    // decisionAPI.saveUsersSortAscending(state.decision.id, state.decision.sortAscending, success => {
    //   if (!success) {
    //     // Saving faile so revert to old value
    //     commit(types.MUTATION_SET_FILTER_ON_HAS_NEW_VALUES, oldValue)
    //   }
    // })
    dispatch(types.ACTION_LOAD_DECISION_OPTIONS)
  },
  [types.ACTION_SET_FILTER_REQUIRES_REVIEW]: ({ /*state,*/ commit, dispatch }, newValue) => {
    
    commit(types.MUTATION_SET_FILTER_ON_REQUIRES_REVIEW, newValue)
    
    dispatch(types.ACTION_LOAD_DECISION_OPTIONS)
  },
  [types.ACTION_SET_FILTER_MISSING_SUMMARY]: ({ /*state,*/ commit, dispatch }, newValue) => {
    
    commit(types.MUTATION_SET_FILTER_ON_MISSING_SUMMARY, newValue)
    
    dispatch(types.ACTION_LOAD_DECISION_OPTIONS)
  },
  [types.ACTION_LOAD_OPTION_REASONS]: async ({ state, commit }, optionId) => {
    if(state.decision) {
      // Get option and check if it is a dataRef
      let foundOption = null
      if (state.allOptions) {
        foundOption = state.allOptions.find(next => next.id === optionId)
      }
      const reasons = await optionAPI.getReasons(state.decision.id, optionId)
      if (foundOption && foundOption.dataRef) {
        const refReasons = await optionAPI.getReasons(foundOption.dataRef.decisionId, foundOption.dataRef.optionId)
        if (refReasons) {
          refReasons.forEach(nextRefReason => {
            reasons.push({isRef: true, ...nextRefReason})
          })
        }
      }
      // Commit the reasons array
      commit(types.MUTATION_SET_OPTION_REASONS, {id: optionId, reasons: reasons})
    }
  },
  [types.ACTION_LOAD_OPTION_LAST_COMMENT]: async ({ state, commit }, optionId) => {
    if(state.decision) {
      const lastComment = await optionAPI.getLatestComment(state.decision.id, optionId)
      // Commit the reasons array
      commit(types.MUTATION_SET_OPTION_LAST_COMMENT, {id: optionId, comment: lastComment})
    }
  },
  [types.ACTION_CREATE_OPTION_REASON]: ({ state, commit }, payload) => {
    if(state.decision) {
      optionAPI.createReason(state.decision.id, payload.optionId, payload.reason).then(reasonObj => {
        if (reasonObj) {
          commit(types.MUTATION_ADD_OPTION_REASON, {id: payload.optionId, reason: reasonObj})
        }
      })
    }
  },
  [types.ACTION_DELETE_OPTION_REASON]: ({ state, commit }, payload) => {
    if(state.decision) {
      commit(types.MUTATION_SET_OPTION_REASON_DELETING, {id: payload.optionId, reasonId: payload.reasonId, isDeleting: true})
      optionAPI.deleteReason(state.decision.id, payload.optionId, payload.reasonId).then(success => {
        if (success) {
          commit(types.MUTATION_DELETE_OPTION_REASON, {id: payload.optionId, reasonId: payload.reasonId})
        } else {
          commit(types.MUTATION_SET_OPTION_REASON_DELETING, {id: payload.optionId, reasonId: payload.reasonId, isDeleting: false})
        }
      })
    }
  },
  [types.ACTION_DELETE_OPTION_SUMMARY]: async ({ state, commit }, payload) => {
    if(state.decision) {
      await optionAPI.deleteProductSummary(state.decision.id, payload.optionId)
      commit(types.MUTATION_DELETE_OPTION_SUMMARY, {optionId: payload.optionId})
    }
  },
  [types.ACTION_UPDATE_OPTION_URLS]: ({ state, commit }, payload) => {
    if(state.decision) {
      optionAPI.updateOptionURLs(state.decision.id, payload.storeId, payload.urls).then(response => {
        if (response && payload.parentOptionId) {
          commit(types.MUTATION_UPDATE_OPTION_STORE_URLS, {optionId: payload.parentOptionId, storeId: payload.storeId, urls: payload.urls})
        }
      })
    }
  },
  [types.ACTION_SET_OPTION_URL]: async ({ state, commit }, payload) => {
    if(state.decision) {
      const response  = await optionAPI.setOptionURL(state.decision.id, payload.optionId, payload.url)
      if (response) {
        commit(types.MUTATION_SET_OPTION_URL, {optionId: payload.optionId, url: payload.url})
        return true
      }
    }
    return false
  },
  [types.ACTION_LOAD_OPTION_STORES]: async ({ state, commit }, optionId) => {
    if(state.decision) {

      // Check if the option us a ref. And look up subsitutes for the ref.
      const theOption = state.allOptions.find(option => option.id === optionId)

      if (theOption && theOption.dataRef) {
        // Find subsitutes for the referenced option
        const referenceSubs = await optionAPI.getOptionSubstitutes(theOption.dataRef.decisionId, theOption.dataRef.optionId)
        referenceSubs.forEach((nextSubstituteOption) => {
          const existingOption = state.allOptions.find(next => next.id === nextSubstituteOption.id)
          if (existingOption) {
            // Update existing option
            // Merge the data with the local data
            const mergeData = {
              url: nextSubstituteOption.url,
              imgSrc: nextSubstituteOption.imgSrc,
              criteriaValues: nextSubstituteOption.criteriaValues
            }
            // Merge approvedValues
            if (nextSubstituteOption.approvedValues) {
              mergeData.approvedValues = nextSubstituteOption.approvedValues
            }
            commit(types.MUTATION_UPDATE_OPTION_DATA, {optionId: nextSubstituteOption.id, optionData: mergeData})
          }
          else {
            const subForDataRef = {...nextSubstituteOption}
            subForDataRef.substituteFor = optionId
            subForDataRef.imported = true
            commit(types.MUTATION_ADD_OPTION, subForDataRef)
          }
        })
      }

      // For recommendation boards the options are paginated and subs are not loaded.
      // So load them here
      if (theOption && state.decision && state.decision.isSuggestionDoc) {
        // Find subsitutes for the referenced option
        const referenceSubs = await optionAPI.getOptionSubstitutes(state.decision.id, theOption.id)
        referenceSubs.forEach((nextSubstituteOption) => {
          const existingOption = state.allOptions.find(next => next.id === nextSubstituteOption.id)
          if (!existingOption)
            commit(types.MUTATION_ADD_OPTION, nextSubstituteOption)
        })
      }

      const subs = state.allOptions.filter(nextOption => nextOption.substituteFor === optionId)

      // Build a default store for this one option if it has a URL
      if (theOption && theOption.url) {
        subs.unshift({...theOption})
      }

      const result = {id: optionId, stores: subs}

      // Load data for any stores that are data refs
      for (var i = 0; i < subs.length; i++) {
        const nextStoreOption = subs[i]
        if (nextStoreOption.dataRef && nextStoreOption.id !== optionId /* Don't reload own data */) {
          const optionDataResponse = await optionAPI.getOption(nextStoreOption.dataRef.decisionId, nextStoreOption.dataRef.optionId)
          if (optionDataResponse && optionDataResponse.status === 'success' && optionDataResponse.optionData) {
            // Merge the data with the local data
            const mergeData = {
              url: optionDataResponse.optionData.url,
              imgSrc: optionDataResponse.optionData.imgSrc,
              criteriaValues: optionDataResponse.optionData.criteriaValues
            }
            // Merge approvedValues
            if (optionDataResponse.optionData.approvedValues) {
              mergeData.approvedValues = optionDataResponse.optionData.approvedValues
            }
            // Merge content state
            if (optionDataResponse.optionData.contentState) {
              mergeData.contentState = optionDataResponse.optionData.contentState
            }
            // Local name can be overrided
            if (!nextStoreOption.name) {
              mergeData.name = optionDataResponse.optionData.name
            }
            commit(types.MUTATION_UPDATE_OPTION_DATA, {optionId: nextStoreOption.id, optionData: mergeData})
          }
        }
      }


      commit(types.MUTATION_SET_OPTION_STORES, result)
      return result
    }
    return null
  },
  [types.ACTION_CONVERT_OPTION_TO_GROUP]: async ({ state, commit, dispatch }, optionId) => {
    if(state.decision) {

      // Load the option from the backend as the target might not be in the visible list
      const optionDataResponse = await optionAPI.getOption(state.decision.id, optionId)
      // const theOption = state.allOptions.find(option => option.id === optionId)
      const theOption = optionDataResponse ? optionDataResponse.optionData : null;

      // let subsituteCount = 0
      // if (theOption && theOption.dataRef) {
      //   // Find subsitutes for the referenced option
      //   const referenceSubs = await optionAPI.getOptionSubstitutes(theOption.dataRef.decisionId, theOption.dataRef.optionId)
      //   subsituteCount += referenceSubs.length
      // }
      //
      // const localSubs = state.allOptions.filter(nextOption => nextOption.substituteFor === optionId)
      // subsituteCount += localSubs.length

      let converted = false
      // Duplicate the option as a substitute for this option which becomes a placeholder / group
      if (theOption && theOption.url /*&& subsituteCount === 0*/) {
        // Duplicate the option and make a substitute for this option.
        const newOptionModel = {...theOption}
        delete newOptionModel.id
        newOptionModel.substituteFor = theOption.id

        // Modify this option removing the url so it is no longer scraped.
        const res = await optionAPI.clearOptionURL(state.decision.id, theOption.id)
        if (res && res.status === 'success') {
          // Clear the URL locally
          commit(types.MUTATION_OPTION_CLEAR_URL, theOption.id)
          // Create the new substitute option
          await dispatch(types.ACTION_CREATE_NEW_OPTION, {optionData: newOptionModel})
          converted = true
        }
      }

      return converted
    }
    return null
  }
}

// mutations
const mutations = {
  [commonTypes.MUTATION_RESET_STATE] (state) {
    // console.log('RESETING STATE in Decision Module')
    Object.assign(state, getDefaultState())
  },
  [types.MUTATION_SET_DECISION] (state, decisionObj) {
    state.decision = decisionObj
  },
  [types.MUTATION_SET_EXPANSION_PANEL_STATE] (state, panelState) {
    state.expansionPanelState = panelState
  },
  [types.MUTATION_SET_NEW_CRITERIA_DIALOG_STATE] (state, dialogState) {
    state.newCriteriaDialogState = dialogState
  },
  [types.MUTATION_SET_AUTO_CHECK_OPTION_VALUES] (state, autoCheckOptionValues) {
    state.autoCheckOptionValues = autoCheckOptionValues
  },
  [types.MUTATION_SET_IS_NAVIGATING] (state, isNavigating) {
    state.navigating = isNavigating
  },
  [types.MUTATION_SET_SHOW_SUBSTITUTES] (state, showSubstitutes) {
    state.showSubstitutes = showSubstitutes
  },
  [types.MUTATION_SET_SELECTED_OPTION] (state, option) {
    state.selectedOption = option
  },
  [types.MUTATION_SET_FULLSCREEN_OPTION_OPEN] (state, newValue) {
    state.fullScreenOptionOpen = newValue
  },
  [types.MUTATION_SET_FEATURE_PLUGIN_DIALOG] (state, newValue) {
    state.showFeaturePluginDialog = newValue
  },
  [types.MUTATION_SET_FEATURE_PLUGIN] (state, newValue) {
    state.activeFeaturePlugin = newValue
  },
  [types.MUTATION_SET_SHOW_ALL_CRITERIA] (state, newValue) {
    state.showAllCriteria = newValue
  },
  [types.MUTATION_SET_SHOW_ALL_COMMENTS] (state, newValue) {
    state.showAllComments = newValue
  },
  [types.MUTATION_SET_VIEWING_AS_PUBLISHED] (state, viewingAsPublished) {
    state.viewingAsPublished = viewingAsPublished
  },
  [types.MUTATION_SET_DECISION_OPTION_COUNT] (state, newCount) {
    if (state.decision) {
      Vue.set(state.decision, 'optionsCount', newCount)
    }
  },
  [types.MUTATION_OPTION_PROS_COUNT] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      Vue.set(theOption, 'prosCount', payload.count)
    }
  },
  [types.MUTATION_OPTION_CONS_COUNT] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      Vue.set(theOption, 'consCount', payload.count)
    }
  },
  [types.MUTATION_OPTION_CLEAR_URL] (state, optionId) {
    const theOption = state.allOptions.find(option => option.id === optionId)
    if (theOption) {
      theOption.url = null
    }
  },
  [types.MUTATION_OPTION_SUBSTITUTE_FOR] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      Vue.set(theOption, 'substituteFor', payload.substituteFor)
    }
  },
  [types.MUTATION_SET_FILTER_ON_HAS_NEW_VALUES] (state, filterOn) {
    state.filterOnNewValues = filterOn
  },
  [types.MUTATION_SET_FILTER_ON_UNAPPROVED_OPTIONS] (state, filterOn) {
    state.filterOnUnApprovedOptions = filterOn
  },
  [types.MUTATION_SET_FILTER_ON_REQUIRES_REVIEW] (state, filterOn) {
    state.filterOnRequiresReview = filterOn
  },
  [types.MUTATION_SET_FILTER_ON_MISSING_SUMMARY] (state, filterOn) {
    state.filterOnMissingSummary = filterOn
  },
  [types.MUTATION_INCREMENT_PAGE] (state) {
    if (state.allOptions.length === state.pageSize) {
      state.pageIndex++
    }
  },
  [types.MUTATION_DECREMENT_PAGE] (state) {
    if (state.pageIndex > 0) {
      state.pageIndex--
    }
  },
  [types.MUTATION_SET_PAGE] (state, pageIndex) {
    const totalOptions = state.decision ? state.decision.optionsCount : 0
    const maxPage = totalOptions / state.pageSize
    if (pageIndex >= 0 && pageIndex < maxPage) {
      state.pageIndex = pageIndex
    }
  },
  [types.MUTATION_ADD_NEW_RECOMMENDATION_ID] (state, optionId) {
    state.newlyAddedRecommendations.push(optionId)
  },
  [types.MUTATION_REMOVE_NEW_RECOMMENDATION_ID] (state, optionId) {
    let foundIndex = state.newlyAddedRecommendations.indexOf(optionId)
    if (foundIndex > -1) {
      state.newlyAddedRecommendations.splice(foundIndex, 1)
    }
  },
  [types.MUTATION_SET_RECOMMENDATIONS_BOARD_ID] (state, boardId) {
    state.recommendationsDecisionId = boardId
  },
  [types.MUTATION_SET_DECISION_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.decisionUnsubscribeCallback) {
      state.decisionUnsubscribeCallback()
      state.decisionUnsubscribeCallback = null
    }
    state.decisionUnsubscribeCallback = newCallback
  },
  [types.MUTATION_SET_OPTIONS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.optionsUnSubscribeCallback) {
      state.optionsUnSubscribeCallback()
      state.optionsUnSubscribeCallback = null
    }
    state.optionsUnSubscribeCallback = newCallback
  },
  [types.MUTATION_SET_MEMBERS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.membersUnSubscribeCallback) {
      state.membersUnSubscribeCallback()
      state.membersUnSubscribeCallback = null
    }
    state.membersUnSubscribeCallback = newCallback
  },
  [types.MUTATION_SET_RESEARCH_DOCS_UNSUBSCRIBE_CALLBACK] (state, newCallback) {
    if (state.researchDocsUnSubscribeCallback) {
      state.researchDocsUnSubscribeCallback()
      state.researchDocsUnSubscribeCallback = null
    }
    state.researchDocsUnSubscribeCallback = newCallback
  },
  [types.MUTATION_DECISION_OWNER] (state, uid) {
    if (state.decision) {
      state.decision.owner = uid
    }
  },
  [types.MUTATION_CURRENT_TAXON] (state, taxon) {
    if (state.decision) {
      state.decision.currentTaxon = taxon
    }
  },
  [types.MUTATION_CRITERIA] (state, criteria) {
    if (state.decision) {
      Vue.set(state.decision, 'criteria', criteria)
    }
  },
  [types.MUTATION_SORT_ASCENDING] (state, sortAscending) {
    // if (state.decision) {
    //   state.decision.sortAscending = sortAscending
    // }
    if (state.decision) {
      Vue.set(state.decision, 'sortAscending', sortAscending)
    }
  },
  [types.MUTATION_SORT_METHOD] (state, sortMethodId) {
    // state.sortMethod = sortMethodId
    if (state.decision) {
      Vue.set(state.decision, 'sortMethod', sortMethodId)
    }
  },
  [types.MUTATION_SORT_CRITERIA] (state, criteriaId) {
    // state.sortCriteria = criteriaId
    if (state.decision) {
      Vue.set(state.decision, 'sortCriteria', criteriaId)
    }
  },
  [types.MUTATION_SET_CRITERIA_RANGES] (state, ranges) {
    // state.sortCriteria = criteriaId
    if (state.decision) {
      Vue.set(state.decision, 'criteriaValueRanges', ranges)
    }
  },
  [types.MUTATION_CHOSEN_OPTION] (state, optionId) {
    if (state.decision) {
      // state.decision.chosenOption = optionId
      Vue.set(state.decision, 'chosenOption', optionId)
    }
  },
  [types.MUTATION_SET_CURRENT_LIST] (state, count) {
    if (state.decision) {
      Vue.set(state.decision, 'currentList', count)
    }
  },
  [types.MUTATION_RESEARCH_DOCS] (state, docs) {
    state.allResearchDocs = docs
  },
  [types.MUTATION_OPTIONS] (state, options) {
    state.allOptions = options
  },
  [types.MUTATION_OPTION_NEWLY_ADDED] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} newlyAdded state to ${payload.newlyAdded}`)
      Vue.set(theOption, 'newlyAdded', payload.newlyAdded)
    }
  },
  [types.MUTATION_OPTION_IS_SHORTLISTED] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} shortlisted state to ${payload.isShortListed}`)
      theOption.shortListed = payload.isShortListed
    }
  },
  [types.MUTATION_SHORTLIST_SUMMARY] (state, summaryText) {
    if (state.decision) {
      // state.decision.shortListSummary = summaryText
      // Use set as shortListSummary may not be initialized
      Vue.set(state.decision, 'shortListSummary', summaryText)
    }
  },
  [types.MUTATION_COMMENTS_COUNT] (state, newCount) {
    if (state.decision) {
      // state.decision.shortListSummary = summaryText
      // Use set as shortListSummary may not be initialized
      Vue.set(state.decision, 'commentsCount', newCount)
    }
  },
  [types.MUTATION_OPTION_SCRAPE_STATE] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} scrapeState to ${payload.scrapeState}`)
      Vue.set(theOption, 'scrapeState', payload.scrapeState)
    }
  },
  [types.MUTATION_OPTION_SCRAPE_RESULT] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} scrapeState to ${payload.scrapeState}`)
      // theOption.scrapeResult = payload.scrapeResult
      Vue.set(theOption, 'scrapeResult', payload.scrapeResult)
    }
  },
  [types.MUTATION_OPTION_RE_SCRAPE_RESULT] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} scrapeState to ${payload.scrapeState}`)
      // theOption.scrapeResult = payload.scrapeResult
      Vue.set(theOption, 'rescrapeResult', payload.rescrapeResult)
    }
  },
  [types.MUTATION_OPTION_HASH] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} sha256 state to ${payload.sha256}`)
      theOption.sha256 = payload.sha256
    }
  },
  [types.MUTATION_OPTION_NAME] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} sha256 state to ${payload.sha256}`)
      theOption.name = payload.name
    }
  },
  [types.MUTATION_OPTION_IMAGE] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      theOption.imgSrc = payload.imgSrc
    }
  },
  [types.MUTATION_UPDATE_DECISION] (state, decisionData) {
    if (state.decision) {
      Object.keys(decisionData).forEach(key => {
        Vue.set(state.decision, key, decisionData[key])
      })
    }
  },
  [types.MUTATION_SET_OPTION_LAST_COMMENT] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      Vue.set(theOption, 'lastComment', payload.comment)
    }
  },
  [types.MUTATION_SET_OPTION_REASONS] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} sha256 state to ${payload.sha256}`)
      Vue.set(theOption, 'optionReasons', payload.reasons)
    }
  },
  [types.MUTATION_ADD_OPTION_REASON] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      if (!theOption.optionReasons) {
        Vue.set(theOption, 'optionReasons', [])
      }
      theOption.optionReasons.push(payload.reason)
    }
  },
  [types.MUTATION_SET_OPTION_REASON_DELETING] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption && theOption.optionReasons) {
      const reason = theOption.optionReasons.find(next => next.id === payload.reasonId)
      if (reason) {
        Vue.set(reason, 'deleting', payload.isDeleting)
      }
    }
  },
  [types.MUTATION_DELETE_OPTION_REASON] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption && theOption.optionReasons) {
      const reasonIndex = theOption.optionReasons.findIndex(next => next.id === payload.reasonId)
      if (reasonIndex > -1) {
        theOption.optionReasons.splice(reasonIndex, 1)
      }
    }
  },
  [types.MUTATION_DELETE_OPTION_SUMMARY] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      if (theOption.productSummary)
        Vue.delete(theOption, 'productSummary')
      if (theOption.approvedProductSummary)
        Vue.delete(theOption, 'approvedProductSummary')
    }
  },
  [types.MUTATION_SET_OPTION_SUMMARY] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption && payload.summary) {
      Vue.set(theOption, 'productSummary', payload.summary)
    }
  },
  [types.MUTATION_SET_OPTION_APPROVED_SUMMARY] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption && (payload.summary || payload.summary == null)) {
      Vue.set(theOption, 'approvedProductSummary', payload.summary)
    }
  },
  [types.MUTATION_UPDATE_OPTION_STORE_URLS] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption && theOption.stores) {
      const theStore = theOption.stores.find(store => store.id === payload.storeId)
      if (theStore) {
        Vue.set(theStore, 'urls', payload.urls)
      }
    }
  },
  [types.MUTATION_SET_OPTION_URL] (state, payload) {
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      Vue.set(theOption, 'url', payload.url)
    }
  },
  [types.MUTATION_SET_OPTION_STORES] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      Vue.set(theOption, 'stores', payload.stores)
    }
  },
  [types.MUTATION_OPTION_CRITERIA_VALUES] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} criteria values`)
      theOption.criteriaValues = payload.criteriaValues
    }
  },
  [types.MUTATION_OPTION_SCRAPE_DATA] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} with new data from a scrape result`)
      theOption.name = payload.name
      if (payload.imgSrc) {
        theOption.imgSrc = payload.imgSrc
      }
      theOption.sha256 = payload.sha256
      Vue.set(theOption, 'scrapeResult', payload.scrapeResult)
      Vue.set(theOption, 'criteriaValues', payload.criteriaValues)
      Vue.set(theOption, 'criteriaValueOptions', payload.criteriaValueOptions)

      if (payload.scrapeResult && payload.scrapeResult.productSummary) {
        Vue.set(theOption, 'productSummary', payload.scrapeResult.productSummary.text)
      }
      
      theOption.scrapeState = payload.scrapeState
      theOption.contentPushed = payload.contentPushed

      if (theOption.rescrapeResult) {
        // Clear any old rescrap results
        theOption.rescrapeResult = null
      }

    }
  },
  [types.MUTATION_OPTION_CLEAR_RESCRAPE_DATA] (state, optionId) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === optionId)
    if (theOption && theOption.rescrapeResult) {
      // Clear any rescrape results
      theOption.rescrapeResult = null
    }
  },
  [types.MUTATION_OPTION_APPROVED_DATA] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      // console.log(`Mutating option ${theOption.name} with approved data from a user review of scrape results`)
      theOption.name = payload.name
      if (payload.imgSrc) {
        theOption.imgSrc = payload.imgSrc
      }
      theOption.criteriaValues = payload.criteriaValues
    }
  },
  [types.MUTATION_OPTION_APPROVED_AS_RECOMMENDATION] (state, payload) {
    // const theOption = state.allOptions[payload.id]
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      theOption.approvedRecommendation = payload.approvedRecommendation
    }
  },
  [types.MUTATION_FETCHING_STATUS] (state, status) {
    state.fetchingStatus = status
  },
  [types.MUTATION_FETCHING_OPTIONS_STATUS] (state, status) {
    state.fetchingOptionsStatus = status
  },
  [types.MUTATION_ADD_OPTION] (state, option) {
    state.allOptions.unshift(option)
  },
  [types.MUTATION_REMOVE_OPTION] (state, optionId) {
    const optionIndex = state.allOptions.findIndex(next => next.id === optionId)
    if (optionIndex > -1) {
      state.allOptions.splice(optionIndex, 1)
    }
    // console.log(`Mutating option ${optionId} removing it`)
  },
  [types.MUTATION_UPDATE_OPTION_DATA] (state, payload) {
    // const optionIndex = state.allOptions.findIndex(next => next.id === payload.optionId)
    const theOption = state.allOptions.find(option => option.id === payload.optionId)
    if (theOption) {
      // Merge criteria values with local values tacking precedence
      if (payload.optionData && payload.optionData.criteriaValues) {
        const mergedCriteriaValues = {...payload.optionData.criteriaValues, ...theOption.criteriaValue}
        Vue.set(theOption, 'criteriaValues', mergedCriteriaValues)
      }
      if (theOption.contentState && payload.optionData && payload.optionData.contentState) {
        theOption.contentState.broken = payload.optionData.contentState.broken
      }
      if (payload.optionData && payload.optionData.imgSrc) {
        Vue.set(theOption, 'imgSrc', payload.optionData.imgSrc)
      }
      if (!theOption.name) {
        Vue.set(theOption, 'name', payload.optionData.name)
      }
    // if (optionIndex > -1) {
      // const option = state.allOptions[optionIndex]
      // const mergedData = {...option, ...payload.optionData}
      // state.allOptions[optionIndex] = mergedData
    }
    // console.log(`Mutating option ${optionId} removing it`)
  },
  [types.MUTATION_CLEAR_MEMBERS] (state) {
    state.members.splice(0)
  },
  [types.MUTATION_ADD_MEMBER] (state, memberData) {
    state.members.push(memberData)
  },
  [types.MUTATION_REMOVE_MEMBER] (state, memberId) {
    const memberIndex = state.members.findIndex(next => next.id === memberId)
    if (memberIndex > -1) {
      state.members.splice(memberIndex, 1)
    }
  },
  [types.MUTATION_SET_MEMBER] (state, memberData) {
    if (state.decision) {
      if (!state.decision.members) {
        Vue.set(state.decision, 'members', {})
      }
      Vue.set(state.decision.members, memberData.id, memberData)
    }
  },
  [types.MUTATION_UNSET_MEMBER] (state, memberId) {
    if (state.decision && state.decision.members && state.decision.members[memberId]) {
      Vue.delete(state.decision.members, `${memberId}`)
    }
  },
  [types.MUTATION_ADD_RESEARCH_DOC] (state, doc) {
    state.allResearchDocs.unshift(doc)

  },
  [types.MUTATION_REMOVE_RESEARCH_DOC] (state, docId) {
    const docIndex = state.allResearchDocs.findIndex(next => next.id === docId)
    if (docIndex > -1) {
      state.allResearchDocs.splice(docIndex, 1)
    }
    // console.log(`Mutating research doc ${docId} removing it`)
  },
  // [types.MUTATION_UPDATE_OPTION_CHECKED_TIME] (state, payload) {
  //   // console.log(`attempting to Mutating option ${payload.id}`)
  //   const theOption = state.allOptions.find(option => option.id === payload.id)
  //   if (theOption) {
  //     // console.log(`Mutating option ${theOption.name} sha256 state to ${payload.sha256}`)
  //     Vue.set(theOption, 'lastCheckTime', payload.lastCheckTime)
  //     // theOption.lastCheckTime = payload.lastCheckTime
  //     // console.log(`Mutating option ${payload.id} setting its time ${theOption.lastCheckTime}`)
  //   }
  //   // state.optionCheckTimes[optionId] = Date.now()
  // },
  [types.MUTATION_UPDATE_CONTENT_STATE] (state, payload) {
    // console.log(`attempting to Mutating option ${payload.id}`)
    const theOption = state.allOptions.find(option => option.id === payload.id)
    if (theOption) {
      theOption.contentState = payload.contentState
    }
    // state.optionCheckTimes[optionId] = Date.now()
  },
  [types.MUTATION_SET_FEATURE_DESCRIPTIONS] (state, featureDescriptions) {
    state.featureDescriptions = featureDescriptions
  },
  [types.MUTATION_SET_FEATURE_NOTES] (state, featureNotes) {
    state.featureNotes = featureNotes
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
